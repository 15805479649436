<template>
  <div class="container">
    <table-page dataName="activities"
                ref="table"
                :tabList="tabList"
                :request="fetchActivies">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$router.push('setActivity')">新建活动</el-button>
      </template>
      <el-table-column label="活动图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>

      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="活动路径">
        <template slot-scope="scope">
          {{scope.row.is_index?'已在首页显示':`pages/activity/activityIn?id=${scope.row.id}`}}
        </template>

      </el-table-column>
      <el-table-column label="关联商户">
        <template slot-scope="scope">
          <Merchants :params="scope.row" />
        </template>

      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="250px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.relevance.showModel(scope.row.id)">关联商户</el-button>
          <el-button type="text"
                     @click="$router.push({name:'SetActivity',query:{id:scope.row.id}})">编辑</el-button>
          <el-button type="text"
                     @click="getEntry(scope)">小程序码</el-button>
          <el-button type="text"
                     @click="delActivity(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Relevance ref="relevance"
               @on-success="$refs.table.fetch()" />
    <MiniCode ref="mini"></MiniCode>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import TableImg from '@/components/TableImg'
import Merchants from './components/Merchants'
import Relevance from './components/Relevance'
import MiniCode from '@/components/MiniCode'
import { fetchActivies, delActivity } from '@/api/market'
export default {
  name: 'Activities',
  data () {
    return {
      fetchActivies,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }]
    }
  },
  components: {
    TablePage,
    TableImg,
    Merchants,
    Relevance,
    MiniCode
  },
  methods: {
    getEntry (scope) {
      this.$refs.mini.createMpCode({
        page: 'pages/activity/activityIn',
        scene: 'activity_id=' + scope.row.id
      })
    },
    setTerrace (row) {
      this.$refs.form.showModel(row)
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delActivity({
          activity_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
