<template>
  <el-dialog title="关联商户"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="30%">
    <div>
      <el-input type="textarea"
                :rows="10"
                placeholder="请输入商户号，多个商户号请换行"
                v-model="merchantNum">
      </el-input>
    </div>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="relevance">关联</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { searchMerchantList, relateMerchants } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      dialogVisible: false,
      merchantNum: '',
      id: ''
    }
  },
  components: {
  },
  methods: {
    showModel (id) {
      this.merchantNum = ''
      this.id = id
      this.dialogVisible = true
    },
    async relevance () {
      if (this.merchantNum === '') {
        this.$message.error('商户号不能为空')
        return false
      }
      const params = this.merchantNum.split(/[(\r\n)\r\n]+/)
      const res = await searchMerchantList({
        merchant_nums: params
      })
      if (res.meta.code === 0) {
        const res2 = await relateMerchants({
          activity_id: this.id,
          merchant_ids: res.data.merchant_ids
        })
        if (res2.meta.code === 0) {
          this.$message.success('关联成功')
          this.dialogVisible = false
          this.$emit('on-success')
        } else {
          this.$message.error(res2.meta.msg)
        }
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
